import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import wp from "../../../../assets/images/grupo-995.svg";
import logo from "../../../../assets/images/logo-bee-circle.svg";

import "./index.css";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";
import { HeaderForgotPassword } from "../HeaderForgotPassword";

export const EmailForm = ({ onSend, onBack, loading }) => {
  const { deviceType } = useScreenWidth();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor ingresa tu correo"),
    }),
    onSubmit: (values) => {
      onSend(values.email);
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    // forgetError: state.ForgetPassword.forgetError,
    // forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));
  return (
    <>
      {deviceType === "mobile" && (
        <div>
          <div>
            <HeaderForgotPassword
              title="Recuperar Contraseña"
              subtitle="Tranquilo, muy pronto podrás ingresar nuevamente"
            />
            <div>
              <Container>
                {forgetError && forgetError && (
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {forgetError}
                  </Alert>
                )}
                {forgetSuccessMsg && (
                  <Alert color="success" style={{ marginTop: "13px" }}>
                    {forgetSuccessMsg}
                  </Alert>
                )}
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div>
                    <p className="add-email-text">
                      Digita el correo electrónico con el que te registraste en
                      Bee y te enviaremos un código de verificación
                    </p>
                    <div className="add-email-container">
                      <label className="add-email-label">Email</label>
                      <input
                        name="email"
                        className="add-input-email"
                        placeholder="Ingrese su correo"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                    </div>
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="add-btn-container">
                    <button type="submit" className="send-code-btn" disabled={loading}>
                      Enviar Código
                    </button>
                    <button type="button" className="back-btn">
                      <Link to="/">Regresar</Link>
                    </button>
                  </div>
                </Form>
              </Container>
            </div>
          </div>
        </div>
      )}
      {deviceType === "tablet" && (
        <Card className="overflow-hidden">
          <div className="bg-well bg-softbg-soft-primary">
            <Row>
              <Col xs={7}>
                <div className=" p-4">
                  <h4 className="">Recuperar contraseña</h4>
                  <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={wp} alt="" className="img-fluid" height="90" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              {forgetError && forgetError ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {forgetError}
                </Alert>
              ) : null}
              {forgetSuccessMsg ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {forgetSuccessMsg}
                </Alert>
              ) : null}

              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <div>
                    <p>
                      Digite el correo electrónico con el que se registro en
                      Puntos bee y le enviaremos un código de verificación
                    </p>
                  </div>
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Ingrese su correo"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  <Col sm="12">
                    <Button
                      color="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                    >
                      <span className="text-white">Enviar Código</span>
                    </Button>
                  </Col>
                  <Col className=" mt-2" sm="12">
                    <Link to="/">
                      <Button color="" type="button" className="w-100">
                        Regresar
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
      {deviceType === "desktop" && (
        <Card className="overflow-hidden">
          <div className="bg-well bg-softbg-soft-primary">
            <Row>
              <Col xs={7}>
                <div className=" p-4">
                  <h4 className="">Recuperar contraseña</h4>
                  <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={wp} alt="" className="img-fluid" height="90" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              {forgetError && forgetError ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {forgetError}
                </Alert>
              ) : null}
              {forgetSuccessMsg ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {forgetSuccessMsg}
                </Alert>
              ) : null}

              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <div>
                    <p>
                      Digite el correo electrónico con el que se registro en
                      Puntos bee y le enviaremos un código de verificación
                    </p>
                  </div>
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Ingrese su correo"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  <Col sm="12">
                    <Button
                      color="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                    >
                      <span className="text-white">Enviar Código</span>
                    </Button>
                  </Col>
                  <Col className=" mt-2" sm="12">
                    <Link to="/">
                      <Button color="" type="button" className="w-100">
                        Regresar
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};
