import React from "react";
import { Row, Col, Button } from "reactstrap";
import "./index.css";

const PrizeRightSide = ({ title, handleClick, isLogo, step, nextButton }) => {
  const titleLines = title.split("\n");
  return (
    <div className="align-items-center right-side">
      <div>
        <Row>
          <Col className="m-0 p-0">
            {titleLines.map((line, index) => (
              <Row>
                <span key={index} className="title-col-2">
                  {line}
                </span>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className={'mt-5'}>
          <Col lg={{ size: 6 }} className={"d-flex justify-content-end"}>
            <div className="d-flex flex-row justify-content-center">
              <div
                className={`rounded-circle base-circle ${
                  step === 1 ? "active-circle" : "secondary-circle"
                }`}
              >
                <span style={{fontFamily: "Poppins", fontWeight: 'bold'  }}>1</span>
              </div>
              <div className="ms-2">
                <span className="title-steps">Adjunta tu logo</span>
                <br />
                <span className="subtitle-steps">
                  Configura el logo de tu perfil
                </span>
              </div>
            </div>
          </Col>
          <Col lg={{ size: 6 }} className={"d-flex justify-content-start"}>
            <div className="d-flex flex-row">
              <div
                className={`rounded-circle base-circle ${
                  step === 2 ? "active-circle" : "secondary-circle"
                }`}
              >
                <span style={{fontFamily: "Poppins", fontWeight: 'bold'  }}>2</span>
              </div>
              <div className="ms-2">
                <span className="title-steps">Selecciona tus Premios</span>
                <br />
                <span className="subtitle-steps">
                  Configura tus propios premios
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {nextButton && (
          <Row className="mt-5 justify-content-center align-items-center">
            <Col>
              <div className="text-center">
                <button
                  onClick={handleClick}
                  className={`laststep-button`}
                >
                  Último Paso
                  <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                </button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default PrizeRightSide;
