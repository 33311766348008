import { useState, useEffect } from 'react';

export const useScreenWidth = (width) => {

  const MOBILE_MAX_WIDTH = 767;
  const TABLET_MAX_WIDTH = 1023;


  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [deviceType, setDeviceType] = useState(getDeviceType(window.innerWidth));

  function getDeviceType(width) {
    if (width <= MOBILE_MAX_WIDTH) {
      return 'mobile';
    } else if (width <= TABLET_MAX_WIDTH) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  useEffect(() => {

    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
      setDeviceType(getDeviceType(newScreenWidth));
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  return {screenWidth, deviceType};
}


