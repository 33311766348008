import { createSlice } from "@reduxjs/toolkit";

const showData = ( data ) => {

  if (data === undefined || data === 'undefined') {
    localStorage.removeItem('countrySelected')
    return null;
  }
  localStorage.setItem('countrySelected', JSON.stringify(data))
  return data
   
}


const initialState = {
  isLoading: false,
  items: [],
  countrySelected: JSON.parse(localStorage.getItem('countrySelected')) || null,
};



export const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.items = [];
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
    showCountrySelected: (state, action) => {
      state.countrySelected = action.payload;
      showData(action.payload);
    }
  },
});
export const countrySliceActions = countrySlice.actions;
export default countrySlice.reducer;
