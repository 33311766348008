import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import "sweetalert2/src/sweetalert2.scss";
import "antd/dist/antd.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import ReactGA from "react-ga4"


import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
  integrations: [
    // Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  // tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

ReactGA.initialize("G-Y7X95KNGWD")

ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

reportWebVitals();
