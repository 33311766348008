import * as Yup from "yup";
import { useFormik } from "formik";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";
import logo from "../../../../assets/images/logo-bee-circle.svg";
import wp from "../../../../assets/images/grupo-995.svg";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";

import "./index.css";
import { HeaderForgotPassword } from "../HeaderForgotPassword";

export const ChangePasswordForm = ({ onChange, onBack, loading }) => {
  const { deviceType } = useScreenWidth();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      passwordVerification: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Por favor ingrese su contraseña")
        .matches(
          /^(?=.*[a-z|A-Z|0-9])(?=.{3,})/,
          "Debe contener al menos 3 caracteres"
        ),
      passwordVerification: Yup.string()
        .required("Por favor confirme su contraseña")
        .oneOf([Yup.ref("password")], "Su contraseña no coincide"),
    }),
    onSubmit: (values) => {
      onChange(values.password);
    },
  });

  return (
    <>
      {deviceType === "mobile" && (
        <div>
          <HeaderForgotPassword
            title="Recuperar Contraseña"
            subtitle="Tranquilo, muy pronto podrás ingresar nuevamente"
          />
          <Container>
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="add-password-container">
                <label className="add-password-label">Contraseña</label>
                <input
                  name="password"
                  className="add-password-input"
                  placeholder="Ingrese la nueva contraseña"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="add-password-container">
                <label className="add-password-label">Confirmar contraseña</label>
                <input
                  name="passwordVerification"
                  className="add-password-input"
                  placeholder="Confirmar contraseña"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.passwordVerification || ""}
                  invalid={
                    validation.touched.passwordVerification &&
                    validation.errors.passwordVerification
                      ? true
                      : false
                  }
                />
                {validation.touched.passwordVerification &&
                validation.errors.passwordVerification ? (
                  <FormFeedback type="invalid">
                    {validation.errors.passwordVerification}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="add-password-btn-container">
                  <button
                    color="primary"
                    type="submit"
                    className="change-password-btn"
                    disabled={loading}
                  >
                    Cambiar Contraseña
                  </button>
                  <button
                    color=""
                    type="button"
                    className="back-password-btn"
                    onClick={onBack}
                  >
                    Regresar
                  </button>
              </div>
            </Form>
          </Container>
        </div>
      )}
      {deviceType === "tablet" && (
        <Card className="overflow-hidden">
          <div className="bg-well bg-softbg-soft-primary">
            <Row>
              <Col xs={7}>
                <div className=" p-4">
                  <h4 className="">Recuperar contraseña</h4>
                  <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={wp} alt="" className="img-fluid" height="90" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Contraseña</Label>
                  <Input
                    name="password"
                    className="form-control"
                    placeholder="Ingrese la nueva contraseña"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Confirmar contraseña</Label>
                  <Input
                    name="passwordVerification"
                    className="form-control"
                    placeholder="Confirmar contraseña"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.passwordVerification || ""}
                    invalid={
                      validation.touched.passwordVerification &&
                      validation.errors.passwordVerification
                        ? true
                        : false
                    }
                  />
                  {validation.touched.passwordVerification &&
                  validation.errors.passwordVerification ? (
                    <FormFeedback type="invalid">
                      {validation.errors.passwordVerification}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  <Col sm="12">
                    <Button
                      color="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                    >
                      <span className="text-white">Cambiar Contraseña</span>
                    </Button>
                  </Col>
                  <Col className=" mt-2" sm="12">
                    <Button
                      color=""
                      type="button"
                      className="w-100"
                      onClick={onBack}
                    >
                      Regresar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
      {deviceType === "desktop" && (
        <Card className="overflow-hidden">
          <div className="bg-well bg-softbg-soft-primary">
            <Row>
              <Col xs={7}>
                <div className=" p-4">
                  <h4 className="">Recuperar contraseña</h4>
                  <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={wp} alt="" className="img-fluid" height="90" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Contraseña</Label>
                  <Input
                    name="password"
                    className="form-control"
                    placeholder="Ingrese la nueva contraseña"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Confirmar contraseña</Label>
                  <Input
                    name="passwordVerification"
                    className="form-control"
                    placeholder="Confirmar contraseña"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.passwordVerification || ""}
                    invalid={
                      validation.touched.passwordVerification &&
                      validation.errors.passwordVerification
                        ? true
                        : false
                    }
                  />
                  {validation.touched.passwordVerification &&
                  validation.errors.passwordVerification ? (
                    <FormFeedback type="invalid">
                      {validation.errors.passwordVerification}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  <Col sm="12">
                    <Button
                      color="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                    >
                      <span className="text-white">Cambiar Contraseña</span>
                    </Button>
                  </Col>
                  <Col className=" mt-2" sm="12">
                    <Button
                      color=""
                      type="button"
                      className="w-100"
                      onClick={onBack}
                    >
                      Regresar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};
