import { useState } from "react";
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap";
import { PlanLayout } from "../../../../layouts/PlanLayout";
import { DotIndicator } from "./Dots";

import "./index.css";
import { useScreenHeight } from "../../../../helpers/useScreenHeight";
import { useIsDesktop } from "../../../../helpers/useIsDesktop";
import { CountryCard } from "./CountryCard";



export const RegisterCountryStep = ({ countries, onNextStep }) => {
  const [selected, setSelected] = useState(undefined);
  const isLargeScreen = useIsDesktop();
  const screenHeight = useScreenHeight();

  return (
    <PlanLayout footer={false}>
      <Container
        fluid
        className="register w-100"
        style={{ minHeight: screenHeight }}
      >
        <Row className="mt-5 justify-content-center">
          <Col sm="12" lg={{ size: 8 }}>
            <h1
              className={
                isLargeScreen
                  ? "register-title text-center mt-4"
                  : "register-title text-center px-5"
              }
            >
              <span className="atention-title">En 3 minutos</span> vas a empezar
              {isLargeScreen ? <br /> : " "}a utilizar bee de gratis
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="12" className="mt-2">
            <h4 className="register-question text-center">
              ¿En que país vas a utilizar bee?
            </h4>
          </Col>
        </Row>
        <Row
          className={"d-flex flex-row-reverse justify-content-center align-item-center mt-4"}
          style={isLargeScreen ? { gap: "8rem" } : { gap: "2rem" }}
        >
          {countries.map((country) => (
            <CountryCard
              key={country.id}
              isChecked={country.id === selected?.id}
              name={country.name}
              imageUrl={country.country_image}
              onChange={() => setSelected(country)}
            />
          ))}
        </Row>
        {isLargeScreen && (
          <>
            <Row className="mt-5">
              <Col sm="12" className="d-flex justify-content-center">
                <button
                  type="button"
                  className="register-buttom-first"
                  disabled={!!!selected}
                  onClick={() => onNextStep(selected)}
                >
                  Empezar Ahora <i className="fas fa-arrow-right" />
                </button>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="d-flex justify-content-center mt-3">
                <p className="register-other-country mt-3">
                  ¿Utilizar bee en otro país?
                </p>
              </Col>
            </Row>
          </>
        )}
        {!isLargeScreen && (
          <>
            <Row>
              <Col sm="12" className="d-flex justify-content-center mt-3">
                <p className="register-other-country mt-3">
                  ¿Utilizar bee en otro país?
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12" className="d-flex justify-content-center">
                <button
                  type="button"
                  className="register-buttom-first"
                  disabled={!!!selected}
                  onClick={() => onNextStep(selected)}
                >
                  Empezar Ahora <i className="fas fa-arrow-right" />
                </button>
              </Col>
            </Row>
          </>
        )}
        {isLargeScreen && (
          <Row>
            <Col sm="12" className="d-flex justify-content-center pt-2 my-3">
              <DotIndicator maxDots={3} dots={1} />
            </Col>
          </Row>
        )}
      </Container>
    </PlanLayout>
  );
};
