import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  items: [],
};

export const currentPrizesSlice = createSlice({
  name: "prize/currentPrices",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.items = [];
    },
    setCurrentPrizes: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});
export const currentPrizesActions = currentPrizesSlice.actions;

export default currentPrizesSlice.reducer;
