import itemRow1 from "../assets/images/plan-item-row1.svg"
import itemRow2 from "../assets/images/plan-2.svg";
import itemRow3 from "../assets/images/plan-3.svg";


export const rowItems = [
    {
      img: itemRow1,
      title: "Garantía de 15 días con prueba GRATIS",
      desc: "Si por cualquier razón no te gustó bee, puedes cancelar y se reintegrará el dinero",
    },
    {
      img: itemRow2,
      title: "Capacitaciones y tutoriales",
      desc: "Incluye capacitación 1-1 con un experto en marketing que te aconsejará para que tengas los mejores resultados",
    },
    {
      img: itemRow3,
      title: "Más de 1000 negocios utilizan bee",
      desc: "Aumentando ventas, fidelizando a sus clientes y evitando que se vayan con la competencia",
    },
  ];