import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  FormFeedback,
  Input,
  Label,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo-bee-circle.svg";
import wp from "../../../../assets/images/grupo-995.svg";

import "./index.css";
import { HeaderForgotPassword } from "../HeaderForgotPassword";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";

export const ValidateCodeForm = ({ onVerify, loading, error, onBack }) => {
  const { deviceType } = useScreenWidth();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required("Por favir ingresa el codigo")
        .min(4, "El codigo debe tener almenos 4 caracteres"),
    }),
    onSubmit: (values) => {
      onVerify(values.code);
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    // forgetError: state.ForgetPassword.forgetError,
    // forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));
  return (
    <>
      {deviceType === "mobile" && (
        <div className="overflow-hidden">
          <HeaderForgotPassword
            title="Recuperar Contraseña"
            subtitle="Un último paso"
          />
          <div>
            <div className="p-2">
              {forgetError && forgetError ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {forgetError}
                </Alert>
              ) : null}
              {forgetSuccessMsg ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {forgetSuccessMsg}
                </Alert>
              ) : null}

              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div>
                  <p className="code-password-text">
                    Ingresa el código que te fue enviado a tu correo
                  </p>
                  <div className="code-password-container">
                    <label className="code-password-label">Código</label>
                    <input
                      name="code"
                      className="code-password-input"
                      placeholder="Ingrese el código"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.code || ""}
                      invalid={
                        validation.touched.code && validation.errors.code
                          ? true
                          : false
                      }
                    />
                  </div>
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  {error && (
                    <Col sm="12">
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    </Col>
                  )}
                  <div className="code-btn-container">
                    <button
                      type="submit"
                      className="add-code-btn"
                      disabled={loading}
                    >
                      Ingresar
                    </button>
                    <button
                      type="button"
                      className="back-code-btn"
                      onClick={onBack}
                    >
                      Regresar
                    </button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      )}
      {deviceType === "tablet" && (
        <Card className="overflow-hidden">
          <div className="bg-well bg-softbg-soft-primary">
            <Row>
              <Col xs={7}>
                <div className=" p-4">
                  <h4 className="">Recuperar contraseña</h4>
                  <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={wp} alt="" className="img-fluid" height="90" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              {forgetError && forgetError ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {forgetError}
                </Alert>
              ) : null}
              {forgetSuccessMsg ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {forgetSuccessMsg}
                </Alert>
              ) : null}
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <div>
                    <p>Ingresa el código que te fue enviado a tu correo</p>
                  </div>
                  <Label className="form-label">Código</Label>
                  <Input
                    name="code"
                    className="form-control"
                    placeholder="Ingrese el código"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code || ""}
                    invalid={
                      validation.touched.code && validation.errors.code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  {error ? (
                    <Col sm="12">
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    </Col>
                  ) : null}
                  <Col sm="12">
                    <Button
                      color="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                    >
                      <span className="text-white">Ingresar</span>
                    </Button>
                  </Col>
                  <Col className=" mt-2" sm="12">
                    <Button
                      color=""
                      type="button"
                      className="w-100"
                      onClick={onBack}
                    >
                      Regresar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
      {deviceType === "desktop" && (
        <Card className="overflow-hidden">
          <div className="bg-well bg-softbg-soft-primary">
            <Row>
              <Col xs={7}>
                <div className=" p-4">
                  <h4 className="">Recuperar contraseña</h4>
                  <p>Tranquilo, muy pronto podrá ingresar nuevamente</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={wp} alt="" className="img-fluid" height="90" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              {forgetError && forgetError ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {forgetError}
                </Alert>
              ) : null}
              {forgetSuccessMsg ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {forgetSuccessMsg}
                </Alert>
              ) : null}
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <div>
                    <p>Ingresa el código que te fue enviado a tu correo</p>
                  </div>
                  <Label className="form-label">Código</Label>
                  <Input
                    name="code"
                    className="form-control"
                    placeholder="Ingrese el código"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code || ""}
                    invalid={
                      validation.touched.code && validation.errors.code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
                <Row className="mb-3">
                  {error ? (
                    <Col sm="12">
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    </Col>
                  ) : null}
                  <Col sm="12">
                    <Button
                      color="primary"
                      type="submit"
                      className="w-100"
                      disabled={loading}
                    >
                      <span className="text-white">Ingresar</span>
                    </Button>
                  </Col>
                  <Col className=" mt-2" sm="12">
                    <Button
                      color=""
                      type="button"
                      className="w-100"
                      onClick={onBack}
                    >
                      Regresar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};
