import { axiosApi } from "../helpers/api_helper";

export class StoreRepository {
  endpoint = "/store";

  async uploadLogo(data) {
    const response = await axiosApi.put(`${this.endpoint}/update-logo`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async getStore() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async partialUpdate(data) {
    const response = await axiosApi.patch(this.endpoint, data);
    if (response.status === 200) {
      return response.data;
    }
    //TODO: raise domain exceptions to handle error when request fail
  }

  async getSchedule() {
    const response = await axiosApi.get(`${this.endpoint}/schedule`);
    return response.data;
  }

  async saveSchedule(data) {
    const response = await axiosApi.post(`${this.endpoint}/schedule`, data);
    return response.data;
  }

  async getUserByStore() {
    const response = await axiosApi.get("/users_by_store");
    return response.data;
  }

  async setCardAmount(amount) {
    return await axiosApi.post("/card-setting-by-category", {
      amount_to_finish_it: amount,
    });
  }

  async getCardConfiguration() {
    return await axiosApi.get("/card-setting-by-category",);
  }
  async setMonthsToExpire(monthsToExpire) {
    return await axiosApi.post(`${this.endpoint}/months_to_expire`, {
      months_to_expire: monthsToExpire,
    });
  }

  async getCardConfig() {
    const response = await axiosApi.get(`${this.endpoint}/card-config`);
    return response.data
  }
}

export default StoreRepository;
