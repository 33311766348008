import { axiosApi } from "../helpers/api_helper";

export class PrizeOptionsRepository {
    endpoint = "/prizes_options";

    async listPrizeIdOrStoreId(prize_id) {
        const response = await axiosApi.get(`${this.endpoint}/${prize_id}`);
        return response.data;
    }

    async deletePrize(prize_id) {
        const response = await axiosApi.delete(`${this.endpoint}/${prize_id}`);
        return response.data;
    }

    async registerCustomOption(data) {
        const response = await axiosApi.post(
            `${this.endpoint}/create-custom`,
            data
        );
        return response.data;
    }

    async registerSelectedOptions(data) {
        const response = await axiosApi.post(
            `${this.endpoint}/create-select-options`,
            data
        );

        return response;
    }
}

export default PrizeOptionsRepository;
