import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "reactstrap";
//import "../../assets/scss/custom/pages/_prizesetup-info.scss";
import { StoreRepository } from "../../repositories/store";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import beeLogo from "../../assets/images/LOGO.png";
import ReactPlayer from "react-player";
import "./index.css";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import { useDispatch, useSelector } from "react-redux";
import { loadCardConfig } from "../../app/store/slices/store/cardConfig/effects";

const PrizeSetUpInstruction = (props) => {
  ////useCheckPaymentMethod();
  const { history } = props;
  const [, setImageCategory] = useState(null);
  const [, setDescription] = useState("");
  const [, setHeader] = useState("");
  const [, setCategory] = useState(null);
  const isDesktop = useIsDesktop();
  const screenHeight = useScreenHeight();
  const dispatch = useDispatch()
  const intl = new Intl.NumberFormat();


  useEffect(() => {
    dispatch(loadCardConfig())
  }, [dispatch])

  const cardConfig = useSelector(
    (state) => state.storeSubscription.cardConfig.items
  );

  const initialAmount = (cardConfig.amountToFinishIt/cardConfig.sealQuantity)*cardConfig.PrizeOneSealNumber

  const handleClick = () => {
    history.push("/prize-setup/1");
  };

  useEffect(() => {
    new StoreRepository()
      .getStore()
      .then((data) => {
        const cat = data.store_category_id;
        setCategory(cat);
        setImageCategory(cat.prize_image_url);
        setDescription(cat.description);
        setHeader(cat.header);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="container-prize" style={{ minHeight: screenHeight }}>
      <Row>
        {isDesktop && (
          <Col lg={{ offset: 1, size: 10 }} className="mt-5">
            <img className="img-fluid" src={beeLogo} alt="Logo Bee"></img>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col
          xs={12}
          lg={12}
          className={`d-flex justify-content-center ${isDesktop ? "" : "mt-5"}`}
        >
          <span className="title">Configuremos los 3 Premios</span>
        </Col>
      </Row>
      <Row className={`${isDesktop ? "mt-5" : "mt-2"}`}>
        <Col xs={12} lg={12} className="d-flex justify-content-center">
          <span className="subtitle">¿Cómo Funciona?</span>
        </Col>
        <Col
          xs={12}
          lg={12}
          className={`d-flex justify-content-center ${isDesktop ? "" : "mt-2"}`}
        >
          {isDesktop ? (
            <p className="description text-center">
              Tus clientes consumen en tu restaurante, van rellenando sellos y
              descubriendo premios. Para
              <br />
              rellenar completamente un sello debe de consumir {cardConfig.currency} {intl.format(initialAmount)}{" "}.
            </p>
          ) : (
            <p className="description text-center text-999">
              Tus clientes compran, rellenan sellos y
              <br />
              ganan premios.
            </p>
          )}
        </Col>
      </Row>
      <Row className="mt-5">
        <Col
          xs={12}
          lg={{ offset: 2, size: 8 }}
          className={`d-flex justify-content-center ${!isDesktop && "mt-3"}`}
        >
          <ReactPlayer
            url="https://youtu.be/gV-dLyeVQB8?si=AMIWw_0fDjh3JgYF"
            controls={true}
            width={isDesktop ? 800 : window.innerWidth * 0.9}
            height={isDesktop ? 450 : window.innerWidth * 0.9 * 0.5625}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className={`d-flex justify-content-center `} lg={12} xs={12}>
          <div className={`${isDesktop ? "" : "mt-5"}`}>
            <Button onClick={handleClick} className="button-how-work px-5 mb-5">
              Configurar Premios{" "}
              <i className="fa-solid fa-arrow-right text-white ms-2"></i>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

PrizeSetUpInstruction.propTypes = {
  history: PropTypes.object,
};

export default PrizeSetUpInstruction;
