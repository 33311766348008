import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  items: [],
};

export const cardConfigSlice = createSlice({
  name: "store/cardConfig",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.items = []
    },
    loaded: (state, action) => {
      state.isLoading = true;
      state.items = action.payload;
    },
  },
});
export const cardConfigActions = cardConfigSlice.actions;
export default cardConfigSlice.reducer;
