import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik Validation

import {
  sendCode,
  validateCode,
  changePassword,
} from "../../app/store/slices/auth/recovery/effects";
import { authRecoveryActions } from "../../app/store/slices/auth/recovery/slice";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { ChangePasswordForm } from "./ForgotPassword/ChangePasswordForm";
import { ValidateCodeForm } from "./ForgotPassword/ValidateCodeForm";
import { EmailForm } from "./ForgotPassword/EmailForm";

const ForgetPasswordPage = (props) => {
  const { deviceType } = useScreenWidth();
  const recovery = useSelector((state) => state.auth.recovery);
  const dispatch = useDispatch();

  const history = useHistory();

  const { changed } = recovery;

  useEffect(() => {
    if (changed) {
      history.push("/login");
    }
  }, [history, changed]);

  return (
    <React.Fragment>
      {deviceType === "mobile" && (
        <>
          <div className="forget-password-container">
            <div>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  {!recovery.sent ? (
                    <EmailForm
                      onSend={(email) => dispatch(sendCode(email))}
                      loading={recovery.sending}
                    />
                  ) : !recovery.validated ? (
                    <ValidateCodeForm
                      loading={recovery.sending}
                      error={recovery.error}
                      onVerify={(code) => dispatch(validateCode(code))}
                      onBack={() => dispatch(authRecoveryActions.reset())}
                    />
                  ) : (
                    <ChangePasswordForm
                      loading={recovery.sending}
                      onChange={(password) =>
                        dispatch(
                          changePassword({
                            password,
                            code: recovery.code,
                            email: recovery.email,
                          })
                        )
                      }
                      onBack={() => dispatch(authRecoveryActions.reset())}
                    />
                  )}

                  <div className="mt-5 text-center">
                    <p>© {new Date().getFullYear()} Puntos Bee</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      {deviceType === "tablet" && (
        <>
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  {!recovery.sent ? (
                    <EmailForm
                      onSend={(email) => dispatch(sendCode(email))}
                      loading={recovery.sending}
                    />
                  ) : !recovery.validated ? (
                    <ValidateCodeForm
                      loading={recovery.sending}
                      error={recovery.error}
                      onVerify={(code) => dispatch(validateCode(code))}
                      onBack={() => dispatch(authRecoveryActions.reset())}
                    />
                  ) : (
                    <ChangePasswordForm
                      loading={recovery.sending}
                      onChange={(password) =>
                        dispatch(
                          changePassword({
                            password,
                            code: recovery.code,
                            email: recovery.email,
                          })
                        )
                      }
                      onBack={() => dispatch(authRecoveryActions.reset())}
                    />
                  )}

                  <div className="mt-5 text-center">
                    <p>© {new Date().getFullYear()} Puntos Bee</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
      {deviceType === "desktop" && (
        <>
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  {!recovery.sent ? (
                    <EmailForm
                      onSend={(email) => dispatch(sendCode(email))}
                      loading={recovery.sending}
                    />
                  ) : !recovery.validated ? (
                    <ValidateCodeForm
                      loading={recovery.sending}
                      error={recovery.error}
                      onVerify={(code) => dispatch(validateCode(code))}
                      onBack={() => dispatch(authRecoveryActions.reset())}
                    />
                  ) : (
                    <ChangePasswordForm
                      loading={recovery.sending}
                      onChange={(password) =>
                        dispatch(
                          changePassword({
                            password,
                            code: recovery.code,
                            email: recovery.email,
                          })
                        )
                      }
                      onBack={() => dispatch(authRecoveryActions.reset())}
                    />
                  )}

                  <div className="mt-5 text-center">
                    <p>© {new Date().getFullYear()} Puntos Bee</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
