import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  saving: false,
  data: {},
};

export const storeScheduleSlice = createSlice({
  name: "store/schedule",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.data = [];
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    save: (state, action) => {
      state.saving = true;
    },
    saved: (state, action) => {
      state.saving = false;
    },
    update: (state, action) => {
      const key = Object.keys(action.payload)[0]
      const location = state.data[key];
      const day = Object.keys(action.payload[key])[0]
      let data 
      if (location) {
        data = {...location};
        data[day] = {...data[day], ...action.payload[key][day]}
      } else {
        data = {...action.payload[key]}
      }
      state.data[key] = {...data}
    },
  },
});
export const storeScheduleActions = storeScheduleSlice.actions;
export default storeScheduleSlice.reducer;
