import { axiosApi } from "../helpers/api_helper";

export class CountriesRepository {
  endpoint = "/countries";

  async listAll() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async listCitiesByCountry(id) {
    const response = await axiosApi.get(`${this.endpoint}/city/${id}`);
    return response.data;
  }
}

export default CountriesRepository;
