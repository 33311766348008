import { Card, CardBody, Input } from "reactstrap";

export const CountryCard = ({ name, imageUrl, isChecked, onChange }) => {
    const classes = isChecked ? "register-country-card-selected" : "";
    return (
      <Card className={`shadow-lg ${classes} mt-4 country-card`}>
        <CardBody>
          <div>
            <Input
              type="checkbox"
              className="regitser-country-selector"
              onChange={onChange}
              checked={isChecked}
              readOnly
            />
            <div className="d-flex justify-content-center align-items-start ">
              <figure className="country-flag">
                <img src={imageUrl} alt={name} className="img-fluid" />
              </figure>
            </div>
            <h5 className="text-center country-text">{name}</h5>
          </div>
        </CardBody>
      </Card>
    );
  };