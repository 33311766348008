import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import checkBlue from "../../../../assets/images/check-blue.svg";
import bgImg from "../../../../assets/images/register-store.png";
import beeLogo from "../../../../assets/images/simple_logo.svg";

import "./index.css";
import { DotIndicator } from "./Dots";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { RegisterValidate } from "../../../../repositories/register_validate";
import { useIsDesktop } from "../../../../helpers/useIsDesktop";
import { useScreenHeight } from "../../../../helpers/useScreenHeight";

const LineItem = ({ text }) => {
  return (
    <div
      className="d-flex justify-content-start align-items-center mt-3"
      style={{ gap: 20 }}
    >
      <img src={checkBlue} alt="check" />
      <span className="register-store-list-item">{text}</span>
    </div>
  );
};

const StoreForm = ({
  onBack,
  onSave,
  storeCategories,
  cities,
  initialData,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: initialData });

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useIsDesktop();

  const handleSave = async (data) => {
    if (!data.store_category) {
      data.store_category = storeCategories[0].value;
    }
    setErrorMessage(null);
    setIsLoading(true);
    const validate = await new RegisterValidate().validateNoRepeatRegisterData(
      data.email,
      data.phone
    );
    if (validate.status !== 201) {
      setErrorMessage(validate.message);
    } else {
      onSave(data);
    }
    setIsLoading(false);
  };

  const storeCategory =
    storeCategories.length > 0 ? storeCategories[0].value : undefined;

  return (
    <form>
      <FormGroup>
        <Label className="register-input-label">Nombre del Negocio</Label>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="text"
              placeholder="Nombre de tu empresa"
              className="register-input"
              invalid={errors.name}
            />
          )}
        />
        {errors.name && (
          <span className="text-danger">Ingrese el nombre de su negocio</span>
        )}
      </FormGroup>
      <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
        <Label className="register-input-label">Categoría del comercio</Label>
        <Controller
          name="store_category"
          control={control}
          defaultValue={storeCategory}
          rules={{ required: true, null: false }}
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="select"
              placeholder="Categoría del comercio"
              className="register-input"
              invalid={errors.store_category}
            >
              <option value={null} className="register-input">
                Seleccione una categoría
              </option>
              {storeCategories.map((item) => (
                <option
                  value={item.value}
                  key={item.value}
                  className="register-input"
                >
                  {item.label}
                </option>
              ))}
            </Input>
          )}
        />
        {errors.store_category && (
          <span className="text-danger">Debes seleccionar una categoría</span>
        )}
      </FormGroup>
      {cities.length > 0 && (
        <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
          <Label className="register-input-label">Ciudad</Label>
          <Controller
            name="city_id"
            control={control}
            rules={{ required: true, null: false }}
            render={({ field: { ref, ...params } }) => (
              <Input
                {...params}
                innerRef={ref}
                type="select"
                placeholder="Seleccione tu ciudad"
                className="register-input"
                invalid={errors.store_category}
              >
                <option value={null} className="register-input">
                  Seleccione tu ciudad
                </option>
                {cities.map((item) => (
                  <option
                    value={item.value}
                    key={item.value}
                    className="register-input"
                  >
                    {item.label}
                  </option>
                ))}
              </Input>
            )}
          />
          {errors.store_category && (
            <span className="text-danger">Debes seleccionar una ciudad</span>
          )}
        </FormGroup>
      )}
      <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
        <Label className="register-input-label">Email</Label>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="email"
              placeholder="Correo electrónico"
              className="register-input"
              invalid={errors.email}
            />
          )}
        />
        {errors.email && (
          <span className="text-danger">Ingrese un email valido</span>
        )}
      </FormGroup>
      <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
        <Label className="register-input-label">Teléfono</Label>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: true,
            minLength: 8,
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/,
            },
          }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="tel"
              placeholder="Número del negocio"
              className="register-input"
              invalid={errors.phone}
            />
          )}
        />
        {errors.phone && (
          <span className="text-danger">
            Ingrese un numero de teléfono valido
          </span>
        )}
      </FormGroup>
      {!!errorMessage && (
        <span className="error-message text-danger">{errorMessage}</span>
      )}
      {isDesktop ? (
        <Row
          className={`d-flex ${
            isDesktop ? "pt-lg-3" : "pt-lg-3"
          } justify-content-between`}
        >
          <Col
            xs={12}
            sm="12"
            lg="12"
            className={`d-flex align-items-center 
            ${!isDesktop ? "justify-content-center" : "justify-content-between"}
          `}
          >
            <button
              type="button"
              className={`register-back-button`}
              onClick={onBack}
            >
              Regresar
            </button>
            <button
              type="button"
              className={`register-buttom-second ${isDesktop ? "" : "mt-5"}`}
              onClick={handleSubmit(handleSave)}
              disabled={isLoading}
            >
              Empezar Ahora <i className="fas fa-arrow-right" />
            </button>
          </Col>
        </Row>
      ) : (
        <Row className={`d-flex ${isDesktop ? "pt-lg-5 " : "pt-lg-3"}`}>
          <Col
            sm="12"
            lg="6"
            className={`d-flex align-items-center 
            ${!isDesktop ? "justify-content-center" : ""}
          `}
          >
            <button
              type="button"
              className={`register-buttom-second ${isDesktop ? "" : "mt-4"}`}
              onClick={handleSubmit(handleSave)}
              disabled={isLoading}
            >
              Empezar Ahora <i className="fas fa-arrow-right" />
            </button>
          </Col>
          <Col sm="12" lg="6">
            <button
              type="button"
              className={`register-back-button ${!isDesktop ? "" : "mt-3"}`}
              onClick={onBack}
            >
              Regresar
            </button>
          </Col>
        </Row>
      )}
    </form>
  );
};

export const CreateAccountStoreInformation = ({
  onNextStep,
  onBack,
  storeCategories,
  cities,
  initialData,
}) => {
  const isDesktop = useIsDesktop();
  const heigth = useScreenHeight();

  const styles = isDesktop
    ? {
        maxHeigth: heigth,
        minHeight: heigth,
        maxWidth: window.innerWidth,
        overflow: "hidden",
      }
    : {
        overflowX: "hidden",
      };

  return (
    <Container fluid className="register" style={styles}>
      <Row>
        <Col
          lg={6}
          className={`register-store-info-left shadow ${
            isDesktop ? "p-5" : "py-3 px-5"
          }`}
          style={{ minHeight: window.innerHeight }}
        >
          <Row>
            {isDesktop && (
              <Col sm="12">
                <figure style={{ width: "7.65rem", height: "2.68rem" }}>
                  <img src={beeLogo} alt="" className="img-fluid" />
                </figure>
              </Col>
            )}
            <Col sm="12">
              <h4 className="mt-4 register-store-create-title">
                {isDesktop ? (
                  "Crea tu cuenta"
                ) : (
                  <>
                    El único diseñado
                    <br />
                    100% para pymes
                  </>
                )}
              </h4>
              {!isDesktop && (
                <h3
                  className="mt-1 register-store-create-title"
                  style={{ color: "#999999", fontSize: "16px" }}
                >
                  Crea tu cuenta
                </h3>
              )}
            </Col>
            <Col sm="12" lg="10">
              <div className={`${isDesktop ? "mt-4" : "mt-2"}`}>
                <StoreForm
                  onBack={onBack}
                  initialData={initialData}
                  onSave={onNextStep}
                  storeCategories={storeCategories}
                  cities={cities}
                />
              </div>
            </Col>
            {isDesktop && (
              <Col sm="12" className={`d-flex mt-5 pt-2`}>
                <div>
                  <DotIndicator maxDots={3} dots={2} />
                </div>
              </Col>
            )}
          </Row>
        </Col>
        {isDesktop && (
          <Col className="register-store-container" lg={6} style={{ height: heigth, maxHeight: heigth }}>
            <Container>
            <div>
              <Col sm="12" lg={{  size: 12 }}>
                <div className="mt-3">
                  <h1 className="register-store-info-second">
                  +1000 COMERCIOS REGISTRADOS EN LATINOAMERICA
                  </h1>
                </div>
              </Col>
              <Col sm="12" lg={{  size: 12 }}>
                <div className="mt-3">
                  <h1 className="register-store-info-title">
                  Diseñado para pequeños y medianos negocios
                  </h1>
                </div>
              </Col>
              <Col
                sm="12"
                lg={{ size: 12 }}
                className={`${isDesktop ? "mt-3" : ""}`}
              >
                <div>
                  <LineItem text="Utilizalo de GRATIS" />
                  <LineItem text="No necesitas conocimientos en marketing" />
                  <LineItem
                    text="Diseñando por expertos que crearon planes para grandes
                        empresas"
                  />
                </div>
              </Col>
            </div>
            </Container>
          </Col>
        )}
      </Row>
    </Container>
  );
};
