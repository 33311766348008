import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoginRepository } from "../../repositories/login";
import logo from "../../assets/images/logo-bee-circle.svg";
import wp from "../../assets/images/grupo-995.svg";
import "../../assets/scss/custom/pages/_login.scss";
import StoreRepository from "../../repositories/store";
import { getRouteByConfigurationStep } from "../../routes/utils";
import { useScreenWidth } from "../../hooks/useScreenWidth";

import "./index.css";

const Login = (props) => {
  const { history } = props;
  const [error, setError] = useState("");
  const { deviceType } = useScreenWidth();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(
        "Por favor introduzca su correo electrónico"
      ),
      password: Yup.string().required("Por favor, introduzca su contraseña"),
    }),
    onSubmit: (values) => {
      const repo = new LoginRepository();
      repo
        .login(values)
        .then((data) => {
          const page = getRouteByConfigurationStep(
            data?.user_data?.store?.configuration_step
          );
          if (page) {
            history.push(page);
          } else {
            history.push("/dashboard");
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    },
  });

  const updateInfoAndNavigate = useCallback(async () => {
    const authRepo = new LoginRepository();
    const info = authRepo.getInfoUser();

    if (info) {
      const repository = new StoreRepository();
      const store = await repository.getStore();
      if (!!!store) {
        authRepo.logOut();
        return;
      }
      info.store = {
        ...store,
      };
      authRepo.setUser(info);
      const page = getRouteByConfigurationStep(store.configuration_step);
      if (page) {
        history.push(page);
      }
    }
  }, [history]);

  useEffect(() => {
    updateInfoAndNavigate();
  }, [updateInfoAndNavigate]);

  return (
    <>
      {deviceType === "mobile" && (
        <div className="login-container">
          <div className="info-login-container">
            <Container>
              <div className="info-login-text">
                <h3 className="welcome-login">¡Bienvenido!</h3>
                <p className="instructions-login">Inicia sesión para acceder a tu plataforma</p>
                <p className="name-business">Puntos bee Business</p>
              </div>
              <div className="logo-bee-login-container">
                <img src={logo} alt="logo bee" className="logo-bee-login" />
              </div>
            </Container>
          </div>
          <div className="login-form-container">
            <Container>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}

                <div className="login-inputs-container">
                  <label className="form-label">Email</label>
                  <input
                    name="username"
                    placeholder="Ingrese su correo"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="login-inputs-container">
                  <label className="form-label">Contraseña</label>
                  <input
                    name="password"
                    value={validation.values.password || ""}
                    type="password"
                    placeholder="Ingrese su contraseña"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password && (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  )}
                </div>
                <div className="login-inputs-container-remember">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customControlInline"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customControlInline"
                  >
                    Recordarme
                  </label>
                </div>
                <div className="login-btn-container">
                  <button
                    className="login-btn-access"
                    type="submit"
                  >
                    Ingresar
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <Link to="/forgot-password" className="text-muted">
                    <i className="mdi mdi-lock me-1" />
                    ¿Olvid&oacute; su contraseña?
                  </Link>
                </div>
                <div className="mt-2 text-center">
                  <p>
                    ¿No tiene cuenta todavía?
                    <Link to="/register" className="fw-medium text-primary">
                      {" "}
                      Registrate aquí{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Form>
            </Container>
          </div>
        </div>
      )}
      {deviceType === "tablet" && (
        <Container>
          <Row className="pt-5">
            <Col lg={{ offset: 3, size: 6 }}>
              <Card className="shadow">
                <div className="bg-well text-black pb-4">
                  <Row>
                    <Col md="7">
                      <div className=" p-4">
                        <h3
                          style={{
                            font: 'normal normal 900 40px/21px "Avenir"',
                            color: "#454847",
                          }}
                        >
                          ¡Bienvenido!
                        </h3>
                        <p
                          style={{
                            font: 'normal normal medium 20px/24px "Avenir"',
                            letterSpacing: "-0.48px",
                            color: "#454847",
                          }}
                        >
                          Inicia sesión para acceder a tu plataforma
                          <br />
                          <strong
                            style={{
                              font: 'normal normal 900 20px/24px "Avenir"',
                              letterSpacing: " -0.48px",
                              color: "#454847",
                            }}
                          >
                            Puntos bee Business
                          </strong>
                        </p>
                      </div>
                    </Col>
                    <Col md="5" className="align-self-end ps-1">
                      <img src={wp} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="90"
                        />
                      </span>
                    </div>
                  </div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}

                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="username"
                        placeholder="Ingrese su correo"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Contraseña</Label>
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type="password"
                        placeholder="Ingrese su contraseña"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Recordarme
                      </label>
                    </div>
                    <div className="mt-3 d-grid ">
                      <button
                        className="btn btn-block  bg-primary text-white"
                        type="submit"
                      >
                        Ingresar
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" />
                        ¿Olvid&oacute; su contraseña?
                      </Link>
                    </div>
                    <div className="mt-5 text-center">
                      <p>
                        ¿No tiene cuenta todavía?
                        <Link to="/register" className="fw-medium text-primary">
                          {" "}
                          Registrate aquí{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {deviceType === "desktop" && (
        <Container>
          <Row className="pt-5">
            <Col lg={{ offset: 3, size: 6 }}>
              <Card className="shadow">
                <div className="bg-well text-black pb-4">
                  <Row>
                    <Col md="7">
                      <div className=" p-4">
                        <h3
                          style={{
                            font: 'normal normal 900 40px/21px "Avenir"',
                            color: "#454847",
                          }}
                        >
                          ¡Bienvenido!
                        </h3>
                        <p
                          style={{
                            font: 'normal normal medium 20px/24px "Avenir"',
                            letterSpacing: "-0.48px",
                            color: "#454847",
                          }}
                        >
                          Inicia sesión para acceder a tu plataforma
                          <br />
                          <strong
                            style={{
                              font: 'normal normal 900 20px/24px "Avenir"',
                              letterSpacing: " -0.48px",
                              color: "#454847",
                            }}
                          >
                            Puntos bee Business
                          </strong>
                        </p>
                      </div>
                    </Col>
                    <Col md="5" className="align-self-end ps-1">
                      <img src={wp} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="90"
                        />
                      </span>
                    </div>
                  </div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}

                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="username"
                        placeholder="Ingrese su correo"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Contraseña</Label>
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type="password"
                        placeholder="Ingrese su contraseña"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Recordarme
                      </label>
                    </div>
                    <div className="mt-3 d-grid ">
                      <button
                        className="btn btn-block  bg-primary text-white"
                        type="submit"
                      >
                        Ingresar
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" />
                        ¿Olvid&oacute; su contraseña?
                      </Link>
                    </div>
                    <div className="mt-5 text-center">
                      <p>
                        ¿No tiene cuenta todavía?
                        <Link to="/register" className="fw-medium text-primary">
                          {" "}
                          Registrate aquí{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
