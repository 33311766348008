import { rowItems } from "../../../../../data/free-data";
import { useScreenHeight } from "../../../../../helpers/useScreenHeight";
import { useScreenWidth } from "../../../../../helpers/useScreenWidth";
import { RowItem } from "../RowItems";
import { Col, Row } from "reactstrap";
import './index.css'

import beeLogo from "../../../../../assets/images/LOGO@3x.png";
import { Button } from "reactstrap";

export const PresentationView = ({setNextStep}) => {
  const screenHeight = useScreenHeight();
  const screenWidth = useScreenWidth();



  return (
    <>
        <div
          style={{
            maxWidth: screenWidth,
            minWidth: screenWidth,
            minHeight: screenHeight,
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <Row>
            <Col
              sm={{ offset: 4, size: 4 }}
              xs={{ offset: 4, size: 4 }}
              className="mt-1"
            >
              <div className="mt-5">
                <img
                  src={beeLogo}
                  alt="logo bee"
                  className="img-fluid"
                  style={{ transform: "scale(0.7)" }}
                />
              </div>
            </Col>
            <div className="mt-3">
              {rowItems.map((item, i) => (
                <RowItem title={item.title} img={item.img} key={i} />
              ))}
            </div>
            <Col sm={12}>
              <div className="d-flex justify-content-center mt-5">
                <button
                  onClick={() => setNextStep()}
                  className="button-continue-plan px-5"
                >
                  Continuar
                  <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
    </>
  );
};
