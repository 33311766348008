import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coupon:{},
  loading: false
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.coupon = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
}
});

export const couponActions = couponSlice.actions;
export default couponSlice.reducer;