import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo-bee-circle.svg";

import './index.css'

export const HeaderForgotPassword = ({ title, subtitle }) => {
  return (
    <div className="password-recovery-header">
        <h4 className="password-recovery-title">{title}</h4>
        <p className="password-recovery-subtitle">{subtitle}</p>
      <Link to="/">
        <img src={logo} alt="" className="logo-bee-forgot-password" />
      </Link>
    </div>
  );
};
