import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  isChanged: false,
  pagePayment : 1,
  countPayment: 0,
  billingData: [],
  reactive: false,
  last: [],
  saving: false,
  showModal : false
};

export const billingLatestSlice = createSlice({
  name: "billing/latest",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = null;
      state.countPayment = action.payload.total_pages
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.data = [];
    },
    updatePayment: (state, {payload}) => {
      state.data = payload
    },
    deletePayment: (state, {payload}) => {
      state.data = payload
    },
    setPage: (state, action) => {
      state.pagePayment = action.payload
    },
    setChanged: (state, action) => {
      state.isChanged = action.payload
    },
    addBillingData: (state, action) => {
      state.billingData = action.payload
    },
    reactiveSubs: (state, action) => {
      state.reactive = action.payload
    },
    getLastData: (state, action) => {
      state.last = action.payload
    },
    setSaving: (state, action) => {
      state.saving = action.payload
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload
    }
  },
});
export const billingLatestActions = billingLatestSlice.actions;
export default billingLatestSlice.reducer;
