import i18next from "i18next";
import { toast } from "react-toastify";
import { countrySliceActions } from "./slice";
import CountriesRepository from "../../../../repositories/countries";

const toastConfig = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const loadCountries = () => async (dispatch) => {
  dispatch(countrySliceActions.load());
  const repository = new CountriesRepository();
  try {
    const countries = await repository.listAll();
    if (countries) {
      dispatch(countrySliceActions.loaded(countries));
      dispatch(countrySliceActions.showCountrySelected(countries.id))
    } else {
      dispatch(countrySliceActions.loaded([]));
    }
  } catch (e) {
    toast.info(
      i18next.t("It seems something has gone wrong, please try again"),
      toastConfig
    );
  }
};
