import StoreRepository from "../../../../../repositories/store"
import { cardConfigActions } from "./slice"


export const loadCardConfig = () => async (dispatch) => {
    dispatch(cardConfigActions.load())
    const repository = new StoreRepository()
    try {
        const cardConfig = await repository.getCardConfig()
        if (cardConfig) {
            dispatch(cardConfigActions.loaded(cardConfig))
        }
    } catch (error) {
        console.log(error)
    }
}