import { Container, Row, Col } from "reactstrap";
import "./index.css";
import { useScreenWidth } from "../../hooks/useScreenWidth";

export const PlanLayout = ({ children, footer, header = true }) => {
  const { deviceType } = useScreenWidth();

  return (
    <>
      {deviceType === "mobile" && (
        <Container fluid style={{ borderColor: "#1186FA" }}>
          {header && (
            <Row>
              <Col sm="12" style={{ padding: 0 }}></Col>
            </Row>
          )}
          <Row>{children}</Row>
          {/*footer && (
        <>
          <Row className="mt-4">
            <Col sm="12" lg={{ offset: 3, size: 6 }}>
              <hr />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={{ offset: 3, size: 6 }}>
              <Row>
                <Col sm="4" lg="1" className="d-flex items-align-end">
                  <img
                    src={supportService}
                    className="img-responsive"
                    alt="Secure Payment"
                    style={{ height: "4rem" }}
                  />
                </Col>
                <Col sm="8" lg="11">
                  <Row>
                    <Col>
                      <h3>¿Necesitas ayuda?</h3>
                      <p>
                        Llama al{" "}
                        <a href="tel://+506 7131 2600">+506 7131 2600</a> para
                        obtener ayuda gratuita con la configuración de tu perfil
                        en bee.
                        <br />
                        De lunes a viernes, de 8:00 am a 5:00 pm
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )*/}
        </Container>
      )}
      {deviceType === "tablet" && (
        <Container fluid style={{ borderColor: "#1186FA" }}>
          {header && (
            <Row>
              <Col sm="12" style={{ padding: 0 }}>
                {/* Special info */}
              </Col>
            </Row>
          )}
          <Row>{children}</Row>

          {/*footer && (
        <>
          <Row className="mt-4">
            <Col sm="12" lg={{ offset: 3, size: 6 }}>
              <hr />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={{ offset: 3, size: 6 }}>
              <Row>
                <Col sm="4" lg="1" className="d-flex items-align-end">
                  <img
                    src={supportService}
                    className="img-responsive"
                    alt="Secure Payment"
                    style={{ height: "4rem" }}
                  />
                </Col>
                <Col sm="8" lg="11">
                  <Row>
                    <Col>
                      <h3>¿Necesitas ayuda?</h3>
                      <p>
                        Llama al{" "}
                        <a href="tel://+506 7131 2600">+506 7131 2600</a> para
                        obtener ayuda gratuita con la configuración de tu perfil
                        en bee.
                        <br />
                        De lunes a viernes, de 8:00 am a 5:00 pm
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )*/}
        </Container>
      )}
      {deviceType === "desktop" && (
        <Container fluid style={{ borderColor: "#1186FA" }}>
          {header && (
            <Row>
              <Col sm="12" style={{ padding: 0 }}>
                {/* Special info */}
              </Col>
            </Row>
          )}
          <Row>{children}</Row>

          {/*footer && (
        <>
          <Row className="mt-4">
            <Col sm="12" lg={{ offset: 3, size: 6 }}>
              <hr />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={{ offset: 3, size: 6 }}>
              <Row>
                <Col sm="4" lg="1" className="d-flex items-align-end">
                  <img
                    src={supportService}
                    className="img-responsive"
                    alt="Secure Payment"
                    style={{ height: "4rem" }}
                  />
                </Col>
                <Col sm="8" lg="11">
                  <Row>
                    <Col>
                      <h3>¿Necesitas ayuda?</h3>
                      <p>
                        Llama al{" "}
                        <a href="tel://+506 7131 2600">+506 7131 2600</a> para
                        obtener ayuda gratuita con la configuración de tu perfil
                        en bee.
                        <br />
                        De lunes a viernes, de 8:00 am a 5:00 pm
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )*/}
        </Container>
      )}
    </>
  );
};

PlanLayout.defaultProps = {
  footer: true,
};
