import { combineReducers } from "@reduxjs/toolkit";

import redeemed from "./redeemed/slice";
import currentPrizes from "./currentPrizes/slice";
import currentMonth from "./currentMonth/slice";
import prizes from "./prizes/slice";

export default combineReducers({
  redeemed,
  currentPrizes,
  currentMonth,
  prizes,
});
