import { axiosApi } from "../helpers/api_helper";

export class LocationsRepository {
  endpoint = "/location";

  async addLocation() {
    const response = await axiosApi.post(`${this.endpoint}`);
    return response.data;
  }

  async getLocationsByStore() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async updateLocation(id, data) {
    const response = await axiosApi.put(`${this.endpoint}/` + id, data);
    return response.data;
  }

  async deleteLocation(id) {
    return await axiosApi.delete(`${this.endpoint}/${id}`);
  }
}

export default LocationsRepository;
