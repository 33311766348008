import React from "react";
import { Redirect } from "react-router-dom";

import Logout from "../pages/Authentication/Logout";
import Login from "../pages/Authentication/Login";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Register from "../pages/Authentication/Register";
import PaymentForm from "../pages/Authentication/Plan";
import ProfileDetails from "../pages/Profile/ProfileDetails";
import PrizeSetUpInstruction from "../pages/PrizeSetUp/PrizeSetUpInstruction";
import PrizeSetUp from "../pages/PrizeSetUp/PrizeSetUp";

//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import WelcomeOne from "../pages/WelcomePages/WelcomeOne";
import WelcomeTwo from "../pages/WelcomePages/WelcomeTwo";
import FinishUpPage from "../pages/Profile/FinishUpPage";
import SealSetUp from "../pages/PrizeSetUp/SealSetUp";
import GenericSealSetUp from "../pages/PrizeSetUp/GenericPrizeSetUp";
//import HowItWork from "../pages/Profile/HowItWork";

const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Pricing = React.lazy(() => import("../pages/Authentication/Pricing"));

const authProtectedRoutes = [
  { path: "/logout", component: Logout, exact: true },
  { path: "/plan/:planId", component: PaymentForm },
  { path: "/pricing", component: Pricing, exact: true },
  // { path: "/profile", component: UserProfile },
  { path: "/profile-details", component: ProfileDetails, exact: true },
  { path: "/prize-info", component: PrizeSetUpInstruction, exact: true },
  { path: "/setup-seals", component: SealSetUp, exact: true },
  //{ path: "/how-it-works", component: HowItWork, exact: true },
  { path: "/how-it-works", component: PrizeSetUpInstruction, exact: true },
  {
    path: "/setup-generic-prize/:pageNumber",
    component: GenericSealSetUp,
    exact: true
  },
  { path: "/prize-setup/:pageNumber", component: PrizeSetUp, exact: true },
  { path: "/dashboard", component: Dashboard },
  { path: "/wlcm-one", component: WelcomeOne, exact: true },
  { path: "/wlcm-two", component: WelcomeTwo, exact: true },
  { path: "/finish-up", component: FinishUpPage, exact: true },
  { path: "/pages-404", component: Pages404, exact: true },
  { path: "/pages-500", component: Pages500, exact: true }
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

export { authProtectedRoutes, publicRoutes };
