import React from "react";
import "./index.css";
import prizeSilver from "../../assets/images/prize-silver.svg";
import prizeGold from "../../assets/images/prize-gold.svg";
import redeemedPrize from "../../assets/images/prize.svg";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import bronce from '../../assets/images/bronze-prize.svg'
import silver from '../../assets/images/silver-prize.svg'
import gold from '../../assets/images/gold-prize.svg'

const PrizeCard = ({ category }) => {
  const levels = {
    1: { name: "Bronce", bg: "linear-gradient(to right, #B924B4, #8525B7)" },
    2: { name: "Silver", bg: "linear-gradient(to right, #C0C0C0, #EEEEEF)" },
    3: {
      name: "Gold",
      bg: "linear-gradient(to right, #ECA109, #FAD26A, #ED8E09)"
    }
  };

  const seals = {
    1: {
      values: ["winner", 2, 3, prizeSilver, 5, 6, 7, 8, 9, prizeGold],
      border: "border-bronce"
    },
    2: {
      values: ["X", "X", "X", "winner", 5, 6, 7, 8, 9, prizeGold],
      border: "border-silver"
    },
    3: {
      values: ["X", "X", "X", "X", "X", "X", "X", "X", "X", prizeGold],
      border: "border-gold"
    }
  };

  const activeSeal = {
    1: { value: 0 },
    2: { value: 3 },
    3: { value: 9 }
  };

  const selectedCategory = levels[category];
  const selectSeals = seals[category];
  const selectActiveSeal = activeSeal[category];
  const isDesktop = useIsDesktop();

  const levelsPrize = [
    {
      id: 1,
      url: bronce
    },
    {
      id: 2,
      url: silver
    },
    {
      id: 3,
      url: gold
    },
  ]

  return (
    <div className="prize-img-container">
      {
      levelsPrize.map( level => (
        level.id === category && (
          <img className="prize-img" src={level.url} alt="" />
        )
      ))
    }
    </div>
  );
};

export default PrizeCard;
