export const DotIndicator = ({ maxDots, dots }) => {
  const indicators = Array(maxDots)
    .fill(false)
    .map((i, index) => index < dots);

  return (
    <div className="d-flex" style={{ gap: "15px" }}>
      {indicators.map((filled, index) => (
        <div
          key={index}
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: filled ? "#404757" : "transparent",
            border: "1px solid #404757",
            borderRadius: "50%",
            marginRight: "0.5rem"
          }}
        />
      ))}
    </div>
  );
};
