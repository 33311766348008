import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { authProtectedRoutes, publicRoutes } from "../../routes";
import { getRouteByConfigurationStep } from "../../routes/utils";
import StoreRepository from "../../repositories/store";

export const useCheckSubscription = () => {
    const functionMap = (item) => `/${item.path.split("/")[1]}`;
    const functionFilter = (item) => item !== "/";
    const PrivateRoutes = [
            ...new Set(
                authProtectedRoutes.map(functionMap).filter(functionFilter)
            ),
        ],
        PublicRoutes = [
            ...new Set(publicRoutes.map(functionMap).filter(functionFilter)),
        ];
    const history = useHistory();
    const [store, setStore] = useState(undefined);
    const { pathname } = useLocation();
    const isDesktop = useIsDesktop();

    useEffect(() => {
        const isPublic = PublicRoutes.find((item) => pathname.includes(item));
        const repository = new StoreRepository();
        if (!isPublic)
            repository
                .getStore()
                .then((data) => {
                    setStore(undefined);
                    setStore(data);
                })
                .catch((e) => {
                    console.log(e);
                });
    }, [pathname]);

    useEffect(() => {
        if (store && pathname != "/login") {
            const isPublic = PublicRoutes.find((item) =>
                pathname.includes(item)
            );
            if (isPublic) return;

            const isPrivate = PrivateRoutes.find((item) =>
                pathname.includes(item)
            );
        
            if (isPrivate) {
                if (
                    store?.configuration_completed &&
                    store?.subcription &&
                    store?.configuration_step &&
                    !pathname.startsWith("/dashboard") &&
                    !["/profile-details", "/wlcm-two", "/finish-up"].includes(
                        pathname
                    )
                ) {
                    history.push(
                        getRouteByConfigurationStep(store.configuration_step)
                    );
                } else if (
                    store?.subcription &&
                    (pathname.startsWith("/plan") ||
                        pathname.startsWith("/pricing"))
                ) {
                    if (isDesktop) history.push("/profile-details");
                    else history.push("/finish-up");
                } else if (store?.subcription && isPrivate && store?.configuration_completed) {
                    return
                }
                else if (
                    !store?.subcription &&
                    !pathname.startsWith("/plan") &&
                    !pathname.startsWith("/pricing")
                ) {
                    console.log("llego aqui")
                    history.push("/pricing");
                } else if (
                    !store?.configuration_completed
                    && pathname.startsWith("/dashboard")
                ) {
                    history.push(
                        getRouteByConfigurationStep(store.configuration_step)
                    );
                } 
                
                // add new cases with else if statement
            } else {
                history.push("/dashboard")
            };
        }
    }, [store, pathname]);
};
