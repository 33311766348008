import { useState, useEffect } from "react";

export function useScreenHeight() {
  const [isHeight, setIsHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isHeight;
}
