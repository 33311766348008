import { useState, useEffect } from "react";

export function useScreenWidth() {
  const [isWidth, setIswidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIswidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isWidth;
}
