import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  saving: false,
  error: null,
  items: [],
  selected: null,
  count: 0,
  page: 1,
  dates: {},
};

export const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.items = [];
    },
    setCouponPage: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.items = payload.result;
      state.count = payload.total_pages;
    },
    addCoupons: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.items = [...state.items, ...payload];
    },
    setSaving: (state, { payload }) => {
      state.saving = payload;
    },
    delete: (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload);
      state.selected = null;
    },
    update: (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      state.selected = null;
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    setPage: (state, { payload }) => {
      if (payload <= state.count) {
        state.page = payload;
      }
    },
    setDateRange: (state, action) => {
      state.dates = {
        start_date: action.payload.since,
        end_date: action.payload.until,
      };
    },
  },
});
export const couponSliceActions = couponSlice.actions;
export default couponSlice.reducer;
