import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import "../../assets/scss/custom/pages/_welcome-one.scss";
import logo from "../../assets/images/logo-white.svg";
import phoneImg from "../../assets/images/layouts/wlcm-one.png";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import "./styles.css";

const WelcomeOne = (props) => {
  //useCheckPaymentMethod();
  const { history } = props;
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="wlcm-page-one m-0 p-0" style={{ height: screenHeight }}>
      <Row>
        <Col
          xl={{ offset: 1, size: 5 }}
          lg={{ offset: 1, size: 5 }}
          md={4}
          sm={6}
          xs={12}
        >
          <div className="wlcm-wrp">
            <img className="bee-logo" alt="logo" src={logo} />
            <div className="title">
              <p>Bienvenido a Bee</p>
            </div>
            <div className="sub-title-wlcm">
              <p>¡Hagamos una prueba!</p>
            </div>
            <div className="point-section">
              <ul>
                <li>Descarga el App Bee Business</li>
                <li>Inicia Sesión</li>
                <li>Escanea el Código del Cliente</li>
              </ul>
            </div>
            <div className="btn-div mt-5">
              <Button
                className="button"
                onClick={() => history.push("/wlcm-two")}
              >
                CONTINUAR{" "}
                <i className="fa-solid fa-arrow-right text-white ms-2"></i>
              </Button>
            </div>
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={8}
          sm={6}
          xs={12}
          className="d-flex align-items-end"
          style={{ height: screenHeight }}
        >
          <div className="side-img">
            <img
              className="phone-img-wlcm img-fluid"
              alt="logo"
              src={phoneImg}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WelcomeOne;
