import React from "react";
import { Row, Col, Button } from "reactstrap";
import beeLogo from "../../assets/images/LOGO@3x.png";
import phoneimg from "../../assets/images/layouts/phone-img.png";
import MobileImg from "../../assets//images/profile-details-mockup.svg";
import "./index.css";
import uploadImg from "../../assets/images/upload-button.png";
import uploadImgMovil from "../../assets/images/upload-button-movil@3x.png";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import { useIsDesktop } from "../../helpers/useIsDesktop";

export const ProfileSidePanel = (props) => {
  const { urlLogo, store, logo, uploadLogo, removeIcon } = props;
  const screenHeight = useScreenHeight();
  const isDesktop = useIsDesktop();

  return (
    <div
      style={{
        height: screenHeight,
        overflow: "hidden",
      }}
    >
      {isDesktop && (
        <Row className="">
          <Col md={0} lg={{ offset: 2, size: 8 }}>
            <div className="mt-5">
              <img
                src={beeLogo}
                alt=""
                className="img-fluid"
                width="240"
                height="80"
              />
            </div>
          </Col>
        </Row>
      )}
      <Row className="mt-5">
        <Col sm={12} xs={12} lg={{ offset: 2, size: 8 }}>
          <div className={`${isDesktop ? "" : "text-center"}`}>
            <span className="title-profile-details">
              Personalicemos tu perfil
            </span>
          </div>
          <div className={`${isDesktop ? "" : "text-center"}`}>
            <span className="subtitle-profile-details mt-3">
              Adjunta el logotipo de tu empresa
            </span>
          </div>
        </Col>
      </Row>
      <Row className={`d-flex align-items-center mt-3`}>
        <Col sm={12} xs={12} lg={{ offset: 2, size: 2 }}>
          <div
            className="img-content-profile-details"
            style={{ margin: "auto" }}
          >
            <img
              src={
                urlLogo && !logo
                  ? urlLogo
                  : logo
                  ? URL.createObjectURL(logo)
                  : isDesktop
                  ? uploadImg
                  : uploadImgMovil
              }
              onClick={uploadLogo}
              className="img-fluid"
              alt="Store logo"
            ></img>
            {!!logo && (
              <div className="text-white delete-button" onClick={removeIcon}>
                <i class="fa-solid fa-x"></i>
              </div>
            )}
          </div>
        </Col>
        {isDesktop && (
          <Col sm={12} xs={12} lg={{ offset: 1, size: 5 }}>
            <div className="">
              {!!logo ? (
                <div className="d-flex flex-column">
                  <span className="store-title">{store.name}</span>
                  <span
                    className="store-title change-logo"
                    onClick={uploadLogo}
                  >
                    Cambiar logo
                  </span>
                </div>
              ) : (
                <Button
                  className="gradiant-button px-5 py-2 text-white"
                  onClick={uploadLogo}
                >
                  Subir Logotipo
                </Button>
              )}
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} lg={{ offset: 2, size: 10 }}>
          <Row className="d-flex align-items-center">
            <Col xs={12} lg={12} xl={0}>
              <div className="mobile-div">
                <img src={MobileImg} alt="" className="phone-img" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

ProfileSidePanel.defaultProps = {
  isGeneric: false,
  imgWrapperClass: "mt-5 p-0 pt-4",
};
