import { data } from "autoprefixer";
import { axiosApi } from "../helpers/api_helper";

export class PaymentMethodRepository {
  endpoint = "/store_payment_methods";
  endpoint2 = 'store/billing-information'

  async list() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async getPaymentMethod() {
    return await axiosApi.get(`${this.endpoint}/latest-update`);

  }

  async register(data) {
    const response = await axiosApi.post(
      `${this.endpoint}/first-payment`,
      data
    );
    return response;
  }

  async updateCr(data) {
    const response = await axiosApi.post(`${this.endpoint}/add-payment-method-onvo`, data);

    return response;
  }
  async updateCol(data) {
    const response = await axiosApi.put(`${this.endpoint2}`, data);
    return response;
  }

  async addColBillingInfo() {
    return await axiosApi.get(`${this.endpoint2}`);
  }

  async firstPayment(data) {
    const response = await axiosApi.post(`${this.endpoint}/first-payment-onvo`, data);
    return response;
  }

  async secondPayment(data) {
    const response = await axiosApi.post(`${this.endpoint}/first-payment-payvalida`, data);
    return response;
  }
}

export default PaymentMethodRepository;
