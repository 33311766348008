import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  items: [],
  page: 1,
  count: 0,
  isPaying: false,
};

export const billingDetailsSlice = createSlice({
  name: "billing/details",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    loaded: (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
      state.items = payload.result;
      state.count = payload.total_pages;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.items = [];
    },
    nextPage: (state, _) => {
      if (state.page < state.count) {
        state.page = state.page + 1;
      }
    },
    setPaying: (state, { payload }) => {
      state.isPaying = payload;
    },
  },
});
export const billingDetailActions = billingDetailsSlice.actions;
export default billingDetailsSlice.reducer;
