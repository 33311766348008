import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import "./style.scss";
import { useIsDesktop } from "../../../helpers/useIsDesktop";

import nequiLogo from "../../../assets/images/nequi.svg";
import daviPlata from "../../../assets/images/davi-plata.svg";
import pseImage from '../../../assets/images/payment-forms/pse.png'
import { CardForm } from "./PaymenForms/CardForm/CardForm";
import { NequiForm } from "./PaymenForms/NequiForm/NequiForm";
import { DaviPlata } from "./PaymenForms/DaviPlataForm/DaviPlata";
import { AUTH_USER_KEY } from "../../../routes/constants";
import { PSEForm } from "./PaymenForms/PSEForm/PSEForm";

const NequiPaymentMethodForm = ({discount, basePrice, handlerManagePayment }) => {
  return (
    <NequiForm
    discount={discount}
      basePrice={basePrice}
      handlerManagePayment={handlerManagePayment}
    />
  );
};
const CreditCardPaymentMethodForm = ({
  handlerManagePayment,
  handlerNoPayment,
  pricing,
  basePrice,
  discount,
}) => {
  return (
    <CardForm
      handlerManagePayment={handlerManagePayment}
      handlerNoPayment={handlerNoPayment}
      pricing={pricing}
      basePrice={basePrice}
      discount={discount}
    />
  );
};
const DaviPlataPaymentMethodForm = ({
  discount,
  basePrice,
  handlerManagePayment,
}) => {
  return (
    <DaviPlata
      basePrice={basePrice}
      handlerManagePayment={handlerManagePayment}
      discount={discount}
    />
  );
};
const PSEPaymentMethodForm = ({
  discount,
  basePrice,
  handlerManagePayment,
}) => {
  return (
    <PSEForm
      basePrice={basePrice}
      handlerManagePayment={handlerManagePayment}
      discount={discount}
    />
  );
};

const creditCard = 0;
const nequi = 1;
const davi = 2;
const pse = 3;

const paymentMethodsMap = {
  [creditCard]: CreditCardPaymentMethodForm,
  [nequi]: NequiPaymentMethodForm,
  [davi]: DaviPlataPaymentMethodForm,
  [pse]: PSEPaymentMethodForm,
};

const SelectPaymentMethod = ({ children, ref, ...props }) => {
  const [paymentBtnMethod, setPaymentBtnMethod] = useState(creditCard);
  const Component = paymentMethodsMap[paymentBtnMethod];
  return (
    <div>
      <div className="pay-options-col">
        <button
          className={`pay-debit-credit ${
            paymentBtnMethod === creditCard
              ? "payment-active"
              : "payment-no-active"
          }`}
          onClick={() => setPaymentBtnMethod(creditCard)}
        >
          <i class="fa-regular fa-credit-card"></i>
          {"Credito/Debito".toLocaleUpperCase()}
        </button>
        <button
          className={`pay-nequi ${
            paymentBtnMethod === nequi ? "payment-active" : "payment-no-active"
          }`}
          onClick={() => setPaymentBtnMethod(nequi)}
        >
          <img src={nequiLogo} alt="Nequi Logo" />
        </button>
        <button
          className={`pay-davi ${
            paymentBtnMethod === davi ? "payment-active" : "payment-no-active"
          }`}
          onClick={() => setPaymentBtnMethod(davi)}
        >
          <img src={daviPlata} alt="DaviPlata Logo" />
        </button>
        <button
          className={`pay-pse ${
            paymentBtnMethod === pse ? "payment-active" : "payment-no-active"
          }`}
          onClick={() => setPaymentBtnMethod(pse)}
        >
          <img src={pseImage} alt="DaviPlata Logo" />
        </button>
      </div>
      <Component {...props} />
    </div>
  );
};

const PaymentMethodFormV2 = ({
  handlerManagePayment,
  handlerNoPayment,
  pricing,
  basePrice,
  discount,
}) => {
  const isDesktop = useIsDesktop();
  const currentUser = JSON.parse(localStorage.getItem(AUTH_USER_KEY));
  const currentId = currentUser.store.country_id.id

  return (
    <React.Fragment>
      <div
        className=""
        style={isDesktop ? {} : { minHeight: window.innerHeight }}
      >
        <Row
          className="bg-white p-4"
          style={isDesktop ? { borderRadius: "2.5rem" } : {}}
        >
          <Col>
            <Col sm="12">
              <div className="mb-3">
                <h2 className="plan-payment-title">Método de Pago</h2>
              </div>
            </Col>
            <Col sm={12} lg={12}>
              <Col sm={12} lg={12}>
                {currentId !== 2 ? (
                  <CreditCardPaymentMethodForm
                    handlerManagePayment={handlerManagePayment}
                    handlerNoPayment={handlerNoPayment}
                    pricing={pricing}
                    basePrice={basePrice}
                    discount={discount}
                  />
                ) : (
                  <SelectPaymentMethod
                    handlerManagePayment={handlerManagePayment}
                    handlerNoPayment={handlerNoPayment}
                    pricing={pricing}
                    basePrice={basePrice}
                    discount={discount}
                  />
                )}
              </Col>
            </Col>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PaymentMethodFormV2;
