import { Col, Row } from "reactstrap";
import { useIsDesktop } from "../../../../../helpers/useIsDesktop";

export const RowItem = ({ img, title, desc }) => {
    const isDesktop = useIsDesktop();
    return (
      <>
        {isDesktop && (
          <>
          <Row className="mt-2 d-flex align-items-center mb-4">
            <Col xs={3} sm={3} lg="2" className="d-flex justify-content-center">
              <figure className="plan-rowitem-img img-fluid">
                <img
                  src={img}
                  alt=""
                  className="img-fluid"
                  style={{transform: "scale(1.6)"}}
                />
              </figure>
            </Col>
            <Col xs={{ size: 8 }} sm={9} lg="10">
              <div className="">
                <h6 className="plan-rowitem-title">{title}</h6>
                {desc && <p className="plan-rowitem-desc">{desc}</p>}
              </div>
            </Col>
            </Row>
          </>
        )}
        {!isDesktop && (
          <>
          <Row className="mt-1 d-flex align-items-center justify-content-center mb-1">
            <Col xs={3} sm={3} lg="2" className="d-flex justify-content-center">
              <figure className="plan-rowitem-img img-fluid">
                <img
                  src={img}
                  alt=""
                  className="img-fluid"
                  style={{width: "125px"}}
                />
              </figure>
            </Col>
            <Col xs={8} sm={9} lg="10">
              <div className="">
                <h6 className="plan-rowitem-title">{title}</h6>
                {desc && <p className="plan-rowitem-desc">{desc}</p>}
              </div>
            </Col>
            </Row>
          </>
        )}
      </>
    );
  };