import { axiosApi } from "../helpers/api_helper";

export class RegisterValidate {
  endpoint = "store/register-validate";

  async validateNoRepeatRegisterData(contact_email, contact_phone) {
    try {
      const response = await axiosApi.post(`${this.endpoint}`, {
        contact_email,
        contact_phone
      });
      const status = response.status;
      const data = { status };
      data.message = status === 201 ? null : response.response.data.message;
      return data;
    } catch (error) {
      return {
        status: 400,
        message: error?.response?.data?.message || "Ocurrio un error inesperado"
      };
    }
  }
}
