import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadCountries } from "../../../app/store/slices/countries/effects";
import { RegisterCountryStep } from "./components/country";
import { CreateAccountStoreInformation } from "./components/account_store";
import { CreateAccountManagerInfo } from "./components/account_manager";
import StoreCategoriesRepository from "../../../repositories/store_categories";
import { GENERIC_CATEGORY } from "../../../core/constants/store";
import LoginRepository from "../../../repositories/login";
import CountriesRepository from "../../../repositories/countries";
import { countrySliceActions } from "../../../app/store/slices/countries/slice";



export const RegisterPage = ({ history }) => {
  const countries = useSelector((store) => store.countries.items);
  const dispatch = useDispatch();
  const [country, setCountry] = useState(null);
  const [storeCategories, setStoreCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [storeInfo, setStoreInfo] = useState(null);
  const [step, setStep] = useState(1);

  const setCountryAndNextStep = (country) => {
    setCountry(country);
    setStep(2);
  };

  useEffect(() => {
    if (country) {
      const countryId = country.id;
      new StoreCategoriesRepository()
        .listAllByCountry(countryId)
        .then((data) => {
          let index = data.findIndex((c) => c.name === GENERIC_CATEGORY);
          if (index !== undefined) {
            const item = data[index];
            data = data.filter((item, i) => i !== index);
            data.push(item);
          }
          const cleanData = data
            .filter((item) => !!item)
            .map((item) => ({
              value: item.id || "",
              label: item.name,
            }));

          setStoreCategories(cleanData);
          dispatch(countrySliceActions.showCountrySelected(countryId))
        })
        .catch((error) => console.log(error));
      if (countryId === 2) {
        new CountriesRepository()
          .listCitiesByCountry(countryId)
          .then((data) => {
            setCities(
              data.map((city) => ({ label: city.name, value: city.id }))
            );
          })
          .catch((error) => console.log(error));
      }
    }
  }, [country]);

  const setStoreInfoAndnextStep = (info) => {
    setStoreInfo(info);
    setStep(3);
  };

  const setManagerInfoAndnextStep = async (info) => {
    const payload = {
      name: storeInfo.name,
      manager_name: info.name,
      store_category_id: parseInt(storeInfo.store_category),
      country_id: country.id,
      city_id: storeInfo.city_id,
      contact_email: storeInfo.email,
      contact_phone: storeInfo.phone,
      manager_phone: info.phone_manager,
      password: info.password_confirm,
      username: storeInfo.email,
    };
    try {
      await new LoginRepository().register(payload);
      history.push("/pricing");
    } catch (e) {
      console.error(e);
      //setError(e.toString());
    }
  };

  useEffect(() => {
    try {
      const res = new LoginRepository().getInfoUser();
      if (!!res) {
        history.push("/login");
      }
    } catch (err) {
      console.error(err);
    }
  }, [history]);

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  if (step === 1) {
    return (
      <RegisterCountryStep
        countries={countries}
        onNextStep={setCountryAndNextStep}
        step={step}
      />
    );
  } else if (step === 2) {
    return (
      <CreateAccountStoreInformation
        onNextStep={setStoreInfoAndnextStep}
        onBack={() => setStep(1)}
        storeCategories={storeCategories}
        cities={cities}
        initialData={storeInfo}
      />
    );
  } else if (step === 3) {
    return (
      <CreateAccountManagerInfo
        onNextStep={setManagerInfoAndnextStep}
        onBack={() => setStep(2)}
      />
    );
  }
};

export default RegisterPage;
