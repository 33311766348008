import { axiosApi } from "../helpers/api_helper";

export class CouponRespository {
  endpoint = "/coupon";

  async validate(coupon) {
    return await axiosApi.get(`${this.endpoint}/${coupon}`, {});
  }

  async aplicateCoupon(coupon, paymentMethod = {}) {
    return await axiosApi.post(`${this.endpoint}/${coupon}`, paymentMethod);
  }
  async aplicateCouponCol(coupon, paymentMethod = {}) {
    return await axiosApi.post(`${this.endpoint}/colombia/${coupon}`, paymentMethod);
  }
}
