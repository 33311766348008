import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import "../../assets/scss/custom/pages/_welcome-two.scss";
import logo from "../../assets/images/LOGO-white@3x.png";
import phoneImg from "../../assets/images/layouts/wlcm-two.png";
import { useHistory } from "react-router-dom";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import "./styles.css";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import { useIsDesktop } from "../../helpers/useIsDesktop";
const WelcomeOne = () => {
  //useCheckPaymentMethod();
  const history = useHistory();
  const screenHeight = useScreenHeight();
  const isDesktop = useIsDesktop();

  return (
    <div
      className={`m-0 p-0 ${
        isDesktop ? "wlcm-page-two" : "welcome-two-responsive"
      }`}
    >
      <Container fluid>
        <Row>
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <div className={`wlcm-two-wrp mt-5`}>
              <div
                className={`${!isDesktop && "d-flex justify-content-center"}`}
                style={isDesktop ? { height: "4rem" } : { height: "2rem" }}
              >
                <img
                  className="d-flex align-self-center"
                  alt="logo"
                  src={logo}
                  style={{
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className={`title pt-3 ${!isDesktop ? "text-center" : "mt-5"}`}
              >
                <p style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  ¡Así de Fácil!
                </p>
              </div>
              <div
                className={`${
                  isDesktop
                    ? "sub-title-wlcm"
                    : "sub-title-wlcm-responsive text-center mt-n2"
                }`}
              >
                <span>¡Todo listo! Ya puedes empezar</span>
              </div>
              {isDesktop && (
                <div className="description-wlcm">
                  <span>
                    Cuéntale a tus clientes y empieza
                    <br />a darles puntos
                  </span>
                </div>
              )}
              <div
                className={`${
                  isDesktop ? "btn-div mt-5" : " d-flex justify-content-center"
                }`}
              >
                <Button
                  onClick={() => history.push("/dashboard")}
                  className="button mt-4"
                >
                  Continuar{" "}
                  <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={`${
                isDesktop ? "side-img" : "d-flex justify-content-center"
              }`}
            >
              <img
                className={"phone-img-wlcm"}
                alt="logo"
                src={phoneImg}
                height={isDesktop ? window.innerHeight : "auto"}
                width={isDesktop ? window.innerHeight : window.innerWidth * 1.5}
                style={!isDesktop ? { objectPosition: "center" } : {}}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WelcomeOne;
