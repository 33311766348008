import { useScreenWidth } from "../../helpers/useScreenWidth";
import "./index.css";

export const ContainerBottom = ({ children }) => {
  const width = useScreenWidth();
  return (
    <div
      className="container-bottom"
    >
      {children}
    </div>
  );
};
